@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap");

@import "./styles/index";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// html,
// body {
//   font-family: "Noto Sans JP", sans-serif !important;
// }

body {
  position: relative;
}
