@import "./mixin";
@import "./variables";
@import "./custom";

.ant-table-thead > tr > th {
  background: #dcdcdc !important;
}

.scroll {
  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c4c4c4;
  }
}

.search-form-container {
  @include flex(center, space-between);
  margin-bottom: 16px;
}

.search-form {
  flex: 1;
  max-width: 90%;
}

.search-input {
  flex: 0 1 280px;
}

.search-select {
  flex: 0 1 230px;
}

.spin {
  padding: 30px 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.no-padding {
  padding: 0 !important;
}

.answered {
  background-color: #cbf1f5;
  &:hover {
    .ant-table-cell-row-hover {
      background-color: #cbf1f5 !important;
    }
  }
}
.not-answered {
  background-color: #fff7d8;
  &:hover {
    .ant-table-cell-row-hover {
      background-color: #fff7d8 !important;
    }
  }
}

.label-search {
  display: block;
  color: $white-color;
  height: 20px;
  line-height: 20px;
  font-size: 12px !important;
  margin-bottom: 0 !important;
  margin-left: 2px;
  color: $black-color;
}
