$header-height: 71px;
$side-nav-width: 220px;
$dark-grey-color: #393e46;
$dark-color: #222831;
$primary-color: #00adb5;
$white-color: #fff;

$header-height: 71px;
$screen-width: 1280px;
$footer-width: 280px;
$yellow-color: #f5c924;
$yellow1: #f6c626;
$yellow2: #e1ad36;
$linear-yellow: linear-gradient(#f6c626, #f1dc1f);

$zebra-bg-color: #f2f3f5;
$position-select-bg: #9a9b9d;
$orange1: #d84b3d;
$grey1: #707070;
$grey2: #888888;
$grey3: #8b8b8b;
$grey4: #b2b2b2;
$red1: #b92626;
$blue1: #1a6dcc;

$white-color: #fff;
$black-color: #000;
$white-bg-color: #fafbfc;
$milk-punch-color: #fff7d8;
$tundora-color: #444141;
$alto-color: #dcdcdc;
$valencia-color: #d84b3d;
$cupid-color: #fbc6c6;
$curious-blue: #2481df;
$page-bg-color: #f1f1f1;
$border-btn-header-color: #146bc2;
