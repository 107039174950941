@import "/src/styles/index";

.side_nav {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  position: relative;
  grid-column: 1 / span 1;
  background: #fff;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  :global {
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border: none;
    }
    .ant-tooltip-inner > a {
      color: #fff !important;
    }
    .anticon {
      width: 12px;
    }

    // .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    //   background-color: $dark-grey-color !important;
    // }

    .ant-layout-sider {
      background-color: #fff;
    }

    .ant-layout-sider-trigger {
      background-color: #2481df;
    }
  }

  &__sider {
    flex-grow: 1 !important;
    // background: $dark-grey-color !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 48px;
  }
}
