@import "/src/styles/index";

.headerWrapper {
  display: flex;
  width: 100%;
  height: $header-height;
  position: sticky;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 10;
  grid-column: span 2;
  // background-color: $dark-grey-color;
  // color: $white-color;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #008a67 !important;
}

.menuWrapper {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  .menuItem {
    padding: 10px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    .badge {
      position: absolute;
      top: 8px;
      right: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      background-origin: padding-box;
      color: #fff;
      min-width: 14px;
      height: 14px;
      background-color: #eb5757;
      border-radius: 50%;
    }
    &:last-child img {
      border-radius: 50%;
    }
  }
}

.logo {
  height: $header-height;
  // background-color: $dark-color;
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    margin-left: 16px;
    width: 320px;
    object-fit: cover;
  }
}
